// Generated by Framer (23a204f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/H4OHcdXxZyPVKySUy1y2/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const cycleOrder = ["YO9Z5UMLj", "UX2_FvsqV", "GD_TJodPp"];

const variantClassNames = {GD_TJodPp: "framer-v-i2rxa5", UX2_FvsqV: "framer-v-3we08i", YO9Z5UMLj: "framer-v-1w7gtrc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Icon Box": "UX2_FvsqV", Icon: "YO9Z5UMLj", Image: "GD_TJodPp"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; visible2?: boolean; radius?: number; image?: {src: string; srcSet?: string}; icon?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "YO9Z5UMLj", visible2: k217iJZkd = true, radius: iyXhF4bkG = 0, image: i_rMQ_gWY, icon: cPX1BAQiG = "House", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "YO9Z5UMLj", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const isDisplayed = () => {
if (["UX2_FvsqV", "GD_TJodPp"].includes(baseVariant)) return true;
return false
}

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-rOHCn", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1w7gtrc", className)} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"YO9Z5UMLj"} ref={ref} style={{backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: iyXhF4bkG, borderBottomRightRadius: iyXhF4bkG, borderTopLeftRadius: iyXhF4bkG, borderTopRightRadius: iyXhF4bkG, ...style}} transition={transition} variants={{GD_TJodPp: {backgroundColor: "var(--token-b82919fc-e16f-44a3-ab09-20ceff0f0a6c, rgb(237, 242, 247))"}, UX2_FvsqV: {backgroundColor: "var(--token-b82919fc-e16f-44a3-ab09-20ceff0f0a6c, rgb(237, 242, 247))"}}} {...addPropertyOverrides({GD_TJodPp: {"data-framer-name": "Image"}, UX2_FvsqV: {"data-framer-name": "Icon Box"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<Image className={"framer-13f27qe"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"XakJONlnd"} transition={transition} {...addPropertyOverrides({GD_TJodPp: {background: {alt: "", fit: "fill", ...toResponsiveImage(i_rMQ_gWY)}}}, baseVariant, gestureVariant)}/>)}{k217iJZkd && (<motion.div className={"framer-guqv4f-container"} layoutDependency={layoutDependency} layoutId={"ktc1JOYkS-container"} style={{opacity: 1}} transition={transition} variants={{GD_TJodPp: {opacity: 0}}}><Phosphor color={"var(--token-2d0196d5-4c11-460d-91d6-2fb81e415efd, rgb(255, 110, 66)) /* {\"name\":\"Accent - Theme\"} */"} height={"100%"} iconSearch={"House"} iconSelection={cPX1BAQiG} id={"ktc1JOYkS"} layoutId={"ktc1JOYkS"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"light"} width={"100%"}/></motion.div>)}</motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-rOHCn [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rOHCn .framer-18ix39r { display: block; }", ".framer-rOHCn .framer-1w7gtrc { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-rOHCn .framer-13f27qe { flex: none; height: 100%; left: calc(50.00000000000002% - 100% / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 100% / 2); width: 100%; z-index: -1; }", ".framer-rOHCn .framer-guqv4f-container { flex: none; height: 36px; position: relative; width: 36px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-rOHCn .framer-1w7gtrc { gap: 0px; } .framer-rOHCn .framer-1w7gtrc > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-rOHCn .framer-1w7gtrc > :first-child { margin-left: 0px; } .framer-rOHCn .framer-1w7gtrc > :last-child { margin-right: 0px; } }", ".framer-rOHCn.framer-v-3we08i .framer-1w7gtrc, .framer-rOHCn.framer-v-i2rxa5 .framer-1w7gtrc { overflow: hidden; padding: 18px 18px 18px 18px; will-change: transform; }", ".framer-rOHCn.framer-v-3we08i .framer-13f27qe { order: 0; z-index: 0; }", ".framer-rOHCn.framer-v-3we08i .framer-guqv4f-container { order: 1; }", ".framer-rOHCn.framer-v-i2rxa5 .framer-13f27qe { z-index: 0; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 36
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"UX2_FvsqV":{"layout":["auto","auto"]},"GD_TJodPp":{"layout":["auto","auto"]}}}
 * @framerVariables {"k217iJZkd":"visible2","iyXhF4bkG":"radius","i_rMQ_gWY":"image","cPX1BAQiG":"icon"}
 */
const FramerMpeLzA1lr: React.ComponentType<Props> = withCSS(Component, css, "framer-rOHCn") as typeof Component;
export default FramerMpeLzA1lr;

FramerMpeLzA1lr.displayName = "Benefit Image";

FramerMpeLzA1lr.defaultProps = {height: 36, width: 36};

addPropertyControls(FramerMpeLzA1lr, {variant: {options: ["YO9Z5UMLj", "UX2_FvsqV", "GD_TJodPp"], optionTitles: ["Icon", "Icon Box", "Image"], title: "Variant", type: ControlType.Enum}, k217iJZkd: {defaultValue: true, title: "Visible 2", type: ControlType.Boolean}, iyXhF4bkG: {defaultValue: 0, title: "Radius", type: ControlType.Number}, i_rMQ_gWY: {title: "Image", type: ControlType.ResponsiveImage}, cPX1BAQiG: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "House", hidden: undefined, title: "Icon"}} as any)

addFonts(FramerMpeLzA1lr, [...PhosphorFonts])